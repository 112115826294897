@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;700&display=swap');

.list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

a:hover {
    transition: all 500ms;
    opacity: 0.6;
}